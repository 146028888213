import { getLocationMenu } from 'helpers/requests/location-requests';
import { useCallback, useEffect, useState } from 'react';

export const useLocationMenu = locationHandle => {
  const [locationMenus, setLocationMenus] = useState([{ status: 'LOADING' }]);

  // Set location menu
  const handleLocationMenu = useCallback(async () => {
    /* setLocationMenu(prevState => ({
      ...prevState,
      status: 'LOADING',
    })); */
    try {
      const menus = await getLocationMenu(locationHandle);
      const res = [];
      console.log('useLocationMenu handleLocationMenu menus', menus);

      for (const menu of menus) {
        if (menu?.menuCategories?.length > 0) {
          res.push({ ...menu, status: 'SUCCESS' });
        } else {
          res.push({ ...menu, status: 'NULL' });
        }
      }

      console.log('useLocationMenu handleLocationMenu res', res);

      setLocationMenus(res);
    } catch (err) {
      console.log(err);
      setLocationMenus([{ status: 'ERROR' }]);
    }
  }, [locationHandle]);

  useEffect(() => {
    handleLocationMenu();
  }, []);

  // console.log(locationMenu);

  return { locationMenus, handleLocationMenu };
};
