import axios from 'axios';

const apiURL = `${process.env.GATSBY_SERVER_API_URL}/api`;

const MENU_IDS = {
  SAN_FRAN_PIER_41: {
    RESTAURANT_MENU_ID: 199,
    CART_MENU_ID: 161
  }
}

export const getLocationMenu = async locationHandle => {
  const location = await axios.get(`${apiURL}/trucks/${locationHandle}`);

  console.log('getLocationMenu location', location);

  const {
    data: {
      title,
      menu: { id },
    },
  } = { ...location };

  const dummyData = {
    id: 5,
    title: 'Template - Do not delete',
    locationType: null,
    isActive: true,
    updatedAt: '2022-04-19T16:09:49.975Z',
    createAt: '2022-04-12T17:30:53.428Z',
    truckId: 1,
    menuCategories: [
      {
        id: 64,
        categoryId: 13,
        menuId: 5,
        position: '1',
        locationId: null,
        isTemplate: true,
        category: {
          id: 13,
          title: 'Extras',
          subHeading: '',
          locationType: 'TRUCK',
          customTag: null,
          isActive: true,
          updatedAt: '2022-04-12T18:30:25.538Z',
          createAt: '2022-04-12T18:30:25.538Z',
          productCategories: [
            {
              id: 45,
              categoryId: 13,
              productId: 27,
              price: null,
              tag: null,
              position: '1',
              locationId: null,
              isTemplate: true,
              product: {
                id: 27,
                title: 'Bottled Water',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:29:20.361Z',
                createAt: '2022-04-12T18:28:24.650Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 13,
                title: 'Extras',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T18:30:25.538Z',
                createAt: '2022-04-12T18:30:25.538Z',
              },
            },
            {
              id: 47,
              categoryId: 13,
              productId: 10,
              price: null,
              tag: null,
              position: '2',
              locationId: null,
              isTemplate: true,
              product: {
                id: 10,
                title: 'Cape Cod Chips',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:27:05.715Z',
                createAt: '2022-04-12T17:14:15.556Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 13,
                title: 'Extras',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T18:30:25.538Z',
                createAt: '2022-04-12T18:30:25.538Z',
              },
            },
            {
              id: 48,
              categoryId: 13,
              productId: 28,
              price: null,
              tag: null,
              position: '3',
              locationId: null,
              isTemplate: true,
              product: {
                id: 28,
                title: 'Canned Soda',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:29:15.273Z',
                createAt: '2022-04-12T18:28:50.724Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 13,
                title: 'Extras',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T18:30:25.538Z',
                createAt: '2022-04-12T18:30:25.538Z',
              },
            },
            {
              id: 103,
              categoryId: 13,
              productId: 29,
              price: 4,
              tag: null,
              position: '5',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 29,
                title: 'Maine Root Soda',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:29:10.137Z',
                createAt: '2022-04-12T18:29:02.930Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 13,
                title: 'Extras',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T18:30:25.538Z',
                createAt: '2022-04-12T18:30:25.538Z',
              },
            },
            {
              id: 104,
              categoryId: 13,
              productId: 9,
              price: 4,
              tag: null,
              position: '4',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 9,
                title: 'Whoopie Pie',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:27:17.767Z',
                createAt: '2022-04-12T17:13:52.099Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 13,
                title: 'Extras',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T18:30:25.538Z',
                createAt: '2022-04-12T18:30:25.538Z',
              },
            },
            {
              id: 105,
              categoryId: 13,
              productId: 28,
              price: 3,
              tag: null,
              position: '3',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 28,
                title: 'Canned Soda',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:29:15.273Z',
                createAt: '2022-04-12T18:28:50.724Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 13,
                title: 'Extras',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T18:30:25.538Z',
                createAt: '2022-04-12T18:30:25.538Z',
              },
            },
            {
              id: 106,
              categoryId: 13,
              productId: 10,
              price: 4.5,
              tag: null,
              position: '2',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 10,
                title: 'Cape Cod Chips',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:27:05.715Z',
                createAt: '2022-04-12T17:14:15.556Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 13,
                title: 'Extras',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T18:30:25.538Z',
                createAt: '2022-04-12T18:30:25.538Z',
              },
            },
            {
              id: 107,
              categoryId: 13,
              productId: 27,
              price: 4,
              tag: null,
              position: '1',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 27,
                title: 'Bottled Water',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:29:20.361Z',
                createAt: '2022-04-12T18:28:24.650Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 13,
                title: 'Extras',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T18:30:25.538Z',
                createAt: '2022-04-12T18:30:25.538Z',
              },
            },
            {
              id: 50,
              categoryId: 13,
              productId: 9,
              price: null,
              tag: null,
              position: '4',
              locationId: null,
              isTemplate: true,
              product: {
                id: 9,
                title: 'Whoopie Pie',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:27:17.767Z',
                createAt: '2022-04-12T17:13:52.099Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 13,
                title: 'Extras',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T18:30:25.538Z',
                createAt: '2022-04-12T18:30:25.538Z',
              },
            },
            {
              id: 49,
              categoryId: 13,
              productId: 29,
              price: null,
              tag: null,
              position: '5',
              locationId: null,
              isTemplate: true,
              product: {
                id: 29,
                title: 'Maine Root Soda',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:29:10.137Z',
                createAt: '2022-04-12T18:29:02.930Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 13,
                title: 'Extras',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T18:30:25.538Z',
                createAt: '2022-04-12T18:30:25.538Z',
              },
            },
          ],
        },
        menu: {
          id: 5,
          title: 'Template - Do not delete',
          locationType: null,
          isActive: true,
          updatedAt: '2022-04-19T16:09:49.975Z',
          createAt: '2022-04-12T17:30:53.428Z',
          truckId: 1,
          truck: {
            id: 1,
            title: 'Template Truck',
            url: 'template',
            isActive: true,
            updatedAt: '2022-04-19T17:08:20.613Z',
            createAt: '2022-03-25T15:08:02.565Z',
          },
        },
      },
      {
        id: 16,
        categoryId: 6,
        menuId: 5,
        position: '2',
        locationId: null,
        isTemplate: true,
        category: {
          id: 6,
          title: 'Rolls',
          subHeading: '',
          locationType: 'TRUCK',
          customTag: null,
          isActive: true,
          updatedAt: '2022-04-12T17:24:32.548Z',
          createAt: '2022-04-12T17:24:32.548Z',
          productCategories: [
            {
              id: 20,
              categoryId: 6,
              productId: 4,
              price: null,
              tag: null,
              position: '1',
              locationId: null,
              isTemplate: true,
              product: {
                id: 4,
                title: 'Maine',
                description: 'Maine Lobster, served chilled, touch of mayo, on a New England Roll',
                isActive: true,
                updatedAt: '2022-04-12T18:28:00.006Z',
                createAt: '2022-04-12T17:09:59.388Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 6,
                title: 'Rolls',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:24:32.548Z',
                createAt: '2022-04-12T17:24:32.548Z',
              },
            },
            {
              id: 21,
              categoryId: 6,
              productId: 5,
              price: null,
              tag: null,
              position: '2',
              locationId: null,
              isTemplate: true,
              product: {
                id: 5,
                title: 'Connecticut',
                description:
                  'Maine Lobster, served warm with butter and lemon, on a New England roll',
                isActive: true,
                updatedAt: '2022-04-12T18:27:52.455Z',
                createAt: '2022-04-12T17:10:29.058Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 6,
                title: 'Rolls',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:24:32.548Z',
                createAt: '2022-04-12T17:24:32.548Z',
              },
            },
            {
              id: 88,
              categoryId: 6,
              productId: 4,
              price: 15,
              tag: null,
              position: '1',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 4,
                title: 'Maine',
                description: 'Maine Lobster, served chilled, touch of mayo, on a New England Roll',
                isActive: true,
                updatedAt: '2022-04-12T18:28:00.006Z',
                createAt: '2022-04-12T17:09:59.388Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 6,
                title: 'Rolls',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:24:32.548Z',
                createAt: '2022-04-12T17:24:32.548Z',
              },
            },
            {
              id: 87,
              categoryId: 6,
              productId: 5,
              price: 20.5,
              tag: null,
              position: '2',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 5,
                title: 'Connecticut',
                description:
                  'Maine Lobster, served warm with butter and lemon, on a New England roll',
                isActive: true,
                updatedAt: '2022-04-12T18:27:52.455Z',
                createAt: '2022-04-12T17:10:29.058Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 6,
                title: 'Rolls',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:24:32.548Z',
                createAt: '2022-04-12T17:24:32.548Z',
              },
            },
          ],
        },
        menu: {
          id: 5,
          title: 'Template - Do not delete',
          locationType: null,
          isActive: true,
          updatedAt: '2022-04-19T16:09:49.975Z',
          createAt: '2022-04-12T17:30:53.428Z',
          truckId: 1,
          truck: {
            id: 1,
            title: 'Template Truck',
            url: 'template',
            isActive: true,
            updatedAt: '2022-04-19T17:08:20.613Z',
            createAt: '2022-03-25T15:08:02.565Z',
          },
        },
      },
      {
        id: 20,
        categoryId: 7,
        menuId: 5,
        position: '3',
        locationId: null,
        isTemplate: true,
        category: {
          id: 7,
          title: 'Make it a Combo',
          subHeading: '',
          locationType: 'TRUCK',
          customTag: null,
          isActive: true,
          updatedAt: '2022-04-12T17:25:11.237Z',
          createAt: '2022-04-12T17:25:11.237Z',
          productCategories: [
            {
              id: 24,
              categoryId: 7,
              productId: 24,
              price: null,
              tag: null,
              position: '1',
              locationId: null,
              isTemplate: true,
              product: {
                id: 24,
                title: 'Combo #1',
                description: '+ Chips & drink',
                isActive: true,
                updatedAt: '2022-04-12T18:25:23.777Z',
                createAt: '2022-04-12T17:23:16.022Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 7,
                title: 'Make it a Combo',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:25:11.237Z',
                createAt: '2022-04-12T17:25:11.237Z',
              },
            },
            {
              id: 25,
              categoryId: 7,
              productId: 25,
              price: null,
              tag: null,
              position: '2',
              locationId: null,
              isTemplate: true,
              product: {
                id: 25,
                title: 'Combo #2',
                description: '+ Tots & drink',
                isActive: true,
                updatedAt: '2022-04-12T18:25:18.059Z',
                createAt: '2022-04-12T17:23:37.910Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 7,
                title: 'Make it a Combo',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:25:11.237Z',
                createAt: '2022-04-12T17:25:11.237Z',
              },
            },
            {
              id: 26,
              categoryId: 7,
              productId: 26,
              price: null,
              tag: null,
              position: '3',
              locationId: null,
              isTemplate: true,
              product: {
                id: 26,
                title: 'Combo #3',
                description: '+ Whoopie pie & drink',
                isActive: true,
                updatedAt: '2022-04-12T18:25:11.163Z',
                createAt: '2022-04-12T17:24:03.851Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 7,
                title: 'Make it a Combo',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:25:11.237Z',
                createAt: '2022-04-12T17:25:11.237Z',
              },
            },
            {
              id: 89,
              categoryId: 7,
              productId: 26,
              price: 34,
              tag: null,
              position: '3',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 26,
                title: 'Combo #3',
                description: '+ Whoopie pie & drink',
                isActive: true,
                updatedAt: '2022-04-12T18:25:11.163Z',
                createAt: '2022-04-12T17:24:03.851Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 7,
                title: 'Make it a Combo',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:25:11.237Z',
                createAt: '2022-04-12T17:25:11.237Z',
              },
            },
            {
              id: 90,
              categoryId: 7,
              productId: 25,
              price: 45,
              tag: null,
              position: '2',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 25,
                title: 'Combo #2',
                description: '+ Tots & drink',
                isActive: true,
                updatedAt: '2022-04-12T18:25:18.059Z',
                createAt: '2022-04-12T17:23:37.910Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 7,
                title: 'Make it a Combo',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:25:11.237Z',
                createAt: '2022-04-12T17:25:11.237Z',
              },
            },
            {
              id: 91,
              categoryId: 7,
              productId: 24,
              price: 67,
              tag: null,
              position: '1',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 24,
                title: 'Combo #1',
                description: '+ Chips & drink',
                isActive: true,
                updatedAt: '2022-04-12T18:25:23.777Z',
                createAt: '2022-04-12T17:23:16.022Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 7,
                title: 'Make it a Combo',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:25:11.237Z',
                createAt: '2022-04-12T17:25:11.237Z',
              },
            },
          ],
        },
        menu: {
          id: 5,
          title: 'Template - Do not delete',
          locationType: null,
          isActive: true,
          updatedAt: '2022-04-19T16:09:49.975Z',
          createAt: '2022-04-12T17:30:53.428Z',
          truckId: 1,
          truck: {
            id: 1,
            title: 'Template Truck',
            url: 'template',
            isActive: true,
            updatedAt: '2022-04-19T17:08:20.613Z',
            createAt: '2022-03-25T15:08:02.565Z',
          },
        },
      },
      {
        id: 17,
        categoryId: 9,
        menuId: 5,
        position: '4',
        locationId: null,
        isTemplate: true,
        category: {
          id: 9,
          title: 'Bisque / Chowder',
          subHeading: 'Cup / Bowl',
          locationType: 'TRUCK',
          customTag: null,
          isActive: true,
          updatedAt: '2022-04-12T17:27:00.662Z',
          createAt: '2022-04-12T17:27:00.662Z',
          productCategories: [
            {
              id: 34,
              categoryId: 9,
              productId: 6,
              price: null,
              tag: null,
              position: '1',
              locationId: null,
              isTemplate: true,
              product: {
                id: 6,
                title: 'New England Clam Chowder',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:27:43.875Z',
                createAt: '2022-04-12T17:13:05.207Z',
                locationType: 'TRUCK',
                tags: [
                  {
                    id: 2,
                    name: 'gluten_free',
                    isActive: true,
                    updatedAt: '2022-04-07T16:52:47.196Z',
                    createAt: '2022-03-25T13:23:23.934Z',
                  },
                ],
              },
              category: {
                id: 9,
                title: 'Bisque / Chowder',
                subHeading: 'Cup / Bowl',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:27:00.662Z',
                createAt: '2022-04-12T17:27:00.662Z',
              },
            },
            {
              id: 35,
              categoryId: 9,
              productId: 7,
              price: null,
              tag: null,
              position: '2',
              locationId: null,
              isTemplate: true,
              product: {
                id: 7,
                title: 'Lobster Bisque',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:27:34.698Z',
                createAt: '2022-04-12T17:13:22.171Z',
                locationType: 'TRUCK',
                tags: [
                  {
                    id: 2,
                    name: 'gluten_free',
                    isActive: true,
                    updatedAt: '2022-04-07T16:52:47.196Z',
                    createAt: '2022-03-25T13:23:23.934Z',
                  },
                ],
              },
              category: {
                id: 9,
                title: 'Bisque / Chowder',
                subHeading: 'Cup / Bowl',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:27:00.662Z',
                createAt: '2022-04-12T17:27:00.662Z',
              },
            },
            {
              id: 93,
              categoryId: 9,
              productId: 6,
              price: 15,
              tag: null,
              position: '1',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 6,
                title: 'New England Clam Chowder',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:27:43.875Z',
                createAt: '2022-04-12T17:13:05.207Z',
                locationType: 'TRUCK',
                tags: [
                  {
                    id: 2,
                    name: 'gluten_free',
                    isActive: true,
                    updatedAt: '2022-04-07T16:52:47.196Z',
                    createAt: '2022-03-25T13:23:23.934Z',
                  },
                ],
              },
              category: {
                id: 9,
                title: 'Bisque / Chowder',
                subHeading: 'Cup / Bowl',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:27:00.662Z',
                createAt: '2022-04-12T17:27:00.662Z',
              },
            },
            {
              id: 92,
              categoryId: 9,
              productId: 7,
              price: 47,
              tag: null,
              position: '2',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 7,
                title: 'Lobster Bisque',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:27:34.698Z',
                createAt: '2022-04-12T17:13:22.171Z',
                locationType: 'TRUCK',
                tags: [
                  {
                    id: 2,
                    name: 'gluten_free',
                    isActive: true,
                    updatedAt: '2022-04-07T16:52:47.196Z',
                    createAt: '2022-03-25T13:23:23.934Z',
                  },
                ],
              },
              category: {
                id: 9,
                title: 'Bisque / Chowder',
                subHeading: 'Cup / Bowl',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:27:00.662Z',
                createAt: '2022-04-12T17:27:00.662Z',
              },
            },
          ],
        },
        menu: {
          id: 5,
          title: 'Template - Do not delete',
          locationType: null,
          isActive: true,
          updatedAt: '2022-04-19T16:09:49.975Z',
          createAt: '2022-04-12T17:30:53.428Z',
          truckId: 1,
          truck: {
            id: 1,
            title: 'Template Truck',
            url: 'template',
            isActive: true,
            updatedAt: '2022-04-19T17:08:20.613Z',
            createAt: '2022-03-25T15:08:02.565Z',
          },
        },
      },
      {
        id: 22,
        categoryId: 10,
        menuId: 5,
        position: '5',
        locationId: null,
        isTemplate: true,
        category: {
          id: 10,
          title: 'Kids Meal',
          subHeading: 'Served with tots',
          locationType: 'TRUCK',
          customTag: null,
          isActive: true,
          updatedAt: '2022-04-12T17:27:46.395Z',
          createAt: '2022-04-12T17:27:46.395Z',
          productCategories: [
            {
              id: 94,
              categoryId: 10,
              productId: 15,
              price: 1,
              tag: null,
              position: '1',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 15,
                title: 'Grilled Cheese or Cheese Quesadilla',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:26:40.726Z',
                createAt: '2022-04-12T17:15:36.494Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 10,
                title: 'Kids Meal',
                subHeading: 'Served with tots',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:27:46.395Z',
                createAt: '2022-04-12T17:27:46.395Z',
              },
            },
            {
              id: 36,
              categoryId: 10,
              productId: 15,
              price: null,
              tag: null,
              position: '1',
              locationId: null,
              isTemplate: true,
              product: {
                id: 15,
                title: 'Grilled Cheese or Cheese Quesadilla',
                description: '',
                isActive: true,
                updatedAt: '2022-04-12T18:26:40.726Z',
                createAt: '2022-04-12T17:15:36.494Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 10,
                title: 'Kids Meal',
                subHeading: 'Served with tots',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:27:46.395Z',
                createAt: '2022-04-12T17:27:46.395Z',
              },
            },
          ],
        },
        menu: {
          id: 5,
          title: 'Template - Do not delete',
          locationType: null,
          isActive: true,
          updatedAt: '2022-04-19T16:09:49.975Z',
          createAt: '2022-04-12T17:30:53.428Z',
          truckId: 1,
          truck: {
            id: 1,
            title: 'Template Truck',
            url: 'template',
            isActive: true,
            updatedAt: '2022-04-19T17:08:20.613Z',
            createAt: '2022-03-25T15:08:02.565Z',
          },
        },
      },
      {
        id: 18,
        categoryId: 11,
        menuId: 5,
        position: '6',
        locationId: null,
        isTemplate: true,
        category: {
          id: 11,
          title: 'Specialties',
          subHeading: '',
          locationType: 'TRUCK',
          customTag: null,
          isActive: true,
          updatedAt: '2022-04-12T17:28:58.516Z',
          createAt: '2022-04-12T17:28:58.516Z',
          productCategories: [
            {
              id: 95,
              categoryId: 11,
              productId: 22,
              price: 22,
              tag: null,
              position: '7',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 22,
                title: 'Chowder Tots',
                description:
                  'Tater tots smothered in New England clam chowder, shredded cheese, bacon',
                isActive: true,
                updatedAt: '2022-04-12T18:25:39.774Z',
                createAt: '2022-04-12T17:20:59.738Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 11,
                title: 'Specialties',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:28:58.516Z',
                createAt: '2022-04-12T17:28:58.516Z',
              },
            },
            {
              id: 96,
              categoryId: 11,
              productId: 21,
              price: 22,
              tag: null,
              position: '6',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 21,
                title: 'Lobster Quesadilla',
                description:
                  'Maine lobster, shredded cheese, pico de gallo, cilantro lime sauce, on a flour tortilla',
                isActive: true,
                updatedAt: '2022-04-12T18:25:46.182Z',
                createAt: '2022-04-12T17:20:31.878Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 11,
                title: 'Specialties',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:28:58.516Z',
                createAt: '2022-04-12T17:28:58.516Z',
              },
            },
            {
              id: 97,
              categoryId: 11,
              productId: 20,
              price: 25,
              tag: null,
              position: '5',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 20,
                title: 'Lobster Tail & Tots',
                description:
                  "Maine's ultimate luxury, 4-5oz lobster tail in shell, served with drawn butter and a side of tater tots",
                isActive: true,
                updatedAt: '2022-04-12T18:25:53.719Z',
                createAt: '2022-04-12T17:19:54.472Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 11,
                title: 'Specialties',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:28:58.516Z',
                createAt: '2022-04-12T17:28:58.516Z',
              },
            },
            {
              id: 98,
              categoryId: 11,
              productId: 19,
              price: 10,
              tag: null,
              position: '4',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 19,
                title: 'Shrimp Tacos (3)',
                description:
                  'North Atlantic shrimp served with cabbage, pico de gallo, cilantro lime sauce, on flour tortillas',
                isActive: true,
                updatedAt: '2022-04-12T18:26:02.199Z',
                createAt: '2022-04-12T17:19:09.470Z',
                locationType: 'TRUCK',
                tags: [
                  {
                    id: 5,
                    name: 'gf_swap_flour_for_corn',
                    isActive: true,
                    updatedAt: '2022-04-07T16:52:47.196Z',
                    createAt: '2022-03-29T20:25:17.074Z',
                  },
                ],
              },
              category: {
                id: 11,
                title: 'Specialties',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:28:58.516Z',
                createAt: '2022-04-12T17:28:58.516Z',
              },
            },
            {
              id: 99,
              categoryId: 11,
              productId: 18,
              price: 11,
              tag: null,
              position: '3',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 18,
                title: 'Lobster Tacos (3)',
                description:
                  'Maine lobster served with cabbage, pico de gallo, cilantro lime sauce, pico de gallo',
                isActive: true,
                updatedAt: '2022-04-12T18:26:10.238Z',
                createAt: '2022-04-12T17:18:09.213Z',
                locationType: 'TRUCK',
                tags: [
                  {
                    id: 5,
                    name: 'gf_swap_flour_for_corn',
                    isActive: true,
                    updatedAt: '2022-04-07T16:52:47.196Z',
                    createAt: '2022-03-29T20:25:17.074Z',
                  },
                ],
              },
              category: {
                id: 11,
                title: 'Specialties',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:28:58.516Z',
                createAt: '2022-04-12T17:28:58.516Z',
              },
            },
            {
              id: 100,
              categoryId: 11,
              productId: 17,
              price: 10,
              tag: null,
              position: '2',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 17,
                title: 'Lobster Tots',
                description:
                  'Tater tots served with warmed Maine lobster, cilantro lime sauce, pico de gallo',
                isActive: true,
                updatedAt: '2022-04-12T18:26:16.568Z',
                createAt: '2022-04-12T17:17:04.160Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 11,
                title: 'Specialties',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:28:58.516Z',
                createAt: '2022-04-12T17:28:58.516Z',
              },
            },
            {
              id: 37,
              categoryId: 11,
              productId: 16,
              price: null,
              tag: null,
              position: '1',
              locationId: null,
              isTemplate: true,
              product: {
                id: 16,
                title: 'Lobster Grilled Cheese',
                description: 'Maine Lobster, pepper jack and cheddar cheese, on Texas style toast',
                isActive: true,
                updatedAt: '2022-04-12T18:26:22.202Z',
                createAt: '2022-04-12T17:16:18.654Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 11,
                title: 'Specialties',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:28:58.516Z',
                createAt: '2022-04-12T17:28:58.516Z',
              },
            },
            {
              id: 38,
              categoryId: 11,
              productId: 17,
              price: null,
              tag: null,
              position: '2',
              locationId: null,
              isTemplate: true,
              product: {
                id: 17,
                title: 'Lobster Tots',
                description:
                  'Tater tots served with warmed Maine lobster, cilantro lime sauce, pico de gallo',
                isActive: true,
                updatedAt: '2022-04-12T18:26:16.568Z',
                createAt: '2022-04-12T17:17:04.160Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 11,
                title: 'Specialties',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:28:58.516Z',
                createAt: '2022-04-12T17:28:58.516Z',
              },
            },
            {
              id: 39,
              categoryId: 11,
              productId: 18,
              price: null,
              tag: null,
              position: '3',
              locationId: null,
              isTemplate: true,
              product: {
                id: 18,
                title: 'Lobster Tacos (3)',
                description:
                  'Maine lobster served with cabbage, pico de gallo, cilantro lime sauce, pico de gallo',
                isActive: true,
                updatedAt: '2022-04-12T18:26:10.238Z',
                createAt: '2022-04-12T17:18:09.213Z',
                locationType: 'TRUCK',
                tags: [
                  {
                    id: 5,
                    name: 'gf_swap_flour_for_corn',
                    isActive: true,
                    updatedAt: '2022-04-07T16:52:47.196Z',
                    createAt: '2022-03-29T20:25:17.074Z',
                  },
                ],
              },
              category: {
                id: 11,
                title: 'Specialties',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:28:58.516Z',
                createAt: '2022-04-12T17:28:58.516Z',
              },
            },
            {
              id: 40,
              categoryId: 11,
              productId: 19,
              price: null,
              tag: null,
              position: '4',
              locationId: null,
              isTemplate: true,
              product: {
                id: 19,
                title: 'Shrimp Tacos (3)',
                description:
                  'North Atlantic shrimp served with cabbage, pico de gallo, cilantro lime sauce, on flour tortillas',
                isActive: true,
                updatedAt: '2022-04-12T18:26:02.199Z',
                createAt: '2022-04-12T17:19:09.470Z',
                locationType: 'TRUCK',
                tags: [
                  {
                    id: 5,
                    name: 'gf_swap_flour_for_corn',
                    isActive: true,
                    updatedAt: '2022-04-07T16:52:47.196Z',
                    createAt: '2022-03-29T20:25:17.074Z',
                  },
                ],
              },
              category: {
                id: 11,
                title: 'Specialties',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:28:58.516Z',
                createAt: '2022-04-12T17:28:58.516Z',
              },
            },
            {
              id: 41,
              categoryId: 11,
              productId: 20,
              price: null,
              tag: null,
              position: '5',
              locationId: null,
              isTemplate: true,
              product: {
                id: 20,
                title: 'Lobster Tail & Tots',
                description:
                  "Maine's ultimate luxury, 4-5oz lobster tail in shell, served with drawn butter and a side of tater tots",
                isActive: true,
                updatedAt: '2022-04-12T18:25:53.719Z',
                createAt: '2022-04-12T17:19:54.472Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 11,
                title: 'Specialties',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:28:58.516Z',
                createAt: '2022-04-12T17:28:58.516Z',
              },
            },
            {
              id: 101,
              categoryId: 11,
              productId: 16,
              price: 18,
              tag: null,
              position: '1',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 16,
                title: 'Lobster Grilled Cheese',
                description: 'Maine Lobster, pepper jack and cheddar cheese, on Texas style toast',
                isActive: true,
                updatedAt: '2022-04-12T18:26:22.202Z',
                createAt: '2022-04-12T17:16:18.654Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 11,
                title: 'Specialties',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:28:58.516Z',
                createAt: '2022-04-12T17:28:58.516Z',
              },
            },
            {
              id: 42,
              categoryId: 11,
              productId: 21,
              price: null,
              tag: null,
              position: '6',
              locationId: null,
              isTemplate: true,
              product: {
                id: 21,
                title: 'Lobster Quesadilla',
                description:
                  'Maine lobster, shredded cheese, pico de gallo, cilantro lime sauce, on a flour tortilla',
                isActive: true,
                updatedAt: '2022-04-12T18:25:46.182Z',
                createAt: '2022-04-12T17:20:31.878Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 11,
                title: 'Specialties',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:28:58.516Z',
                createAt: '2022-04-12T17:28:58.516Z',
              },
            },
            {
              id: 43,
              categoryId: 11,
              productId: 22,
              price: null,
              tag: null,
              position: '7',
              locationId: null,
              isTemplate: true,
              product: {
                id: 22,
                title: 'Chowder Tots',
                description:
                  'Tater tots smothered in New England clam chowder, shredded cheese, bacon',
                isActive: true,
                updatedAt: '2022-04-12T18:25:39.774Z',
                createAt: '2022-04-12T17:20:59.738Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 11,
                title: 'Specialties',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:28:58.516Z',
                createAt: '2022-04-12T17:28:58.516Z',
              },
            },
          ],
        },
        menu: {
          id: 5,
          title: 'Template - Do not delete',
          locationType: null,
          isActive: true,
          updatedAt: '2022-04-19T16:09:49.975Z',
          createAt: '2022-04-12T17:30:53.428Z',
          truckId: 1,
          truck: {
            id: 1,
            title: 'Template Truck',
            url: 'template',
            isActive: true,
            updatedAt: '2022-04-19T17:08:20.613Z',
            createAt: '2022-03-25T15:08:02.565Z',
          },
        },
      },
      {
        id: 19,
        categoryId: 12,
        menuId: 5,
        position: '7',
        locationId: null,
        isTemplate: true,
        category: {
          id: 12,
          title: 'Take Home',
          subHeading: '',
          locationType: 'TRUCK',
          customTag: null,
          isActive: true,
          updatedAt: '2022-04-12T17:29:18.098Z',
          createAt: '2022-04-12T17:29:18.098Z',
          productCategories: [
            {
              id: 102,
              categoryId: 12,
              productId: 23,
              price: 45,
              tag: null,
              position: '1',
              locationId: 6,
              isTemplate: false,
              product: {
                id: 23,
                title: 'The at-Home Lobster Roll Kit (1LB/2LB)',
                description:
                  'Maine lobster meat, New England rolls, butter, mayo, lemons. Everything you need to make our famous at lobster rolls at home!',
                isActive: true,
                updatedAt: '2022-04-12T18:25:31.336Z',
                createAt: '2022-04-12T17:22:35.219Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 12,
                title: 'Take Home',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:29:18.098Z',
                createAt: '2022-04-12T17:29:18.098Z',
              },
            },
            {
              id: 44,
              categoryId: 12,
              productId: 23,
              price: null,
              tag: null,
              position: '1',
              locationId: null,
              isTemplate: true,
              product: {
                id: 23,
                title: 'The at-Home Lobster Roll Kit (1LB/2LB)',
                description:
                  'Maine lobster meat, New England rolls, butter, mayo, lemons. Everything you need to make our famous at lobster rolls at home!',
                isActive: true,
                updatedAt: '2022-04-12T18:25:31.336Z',
                createAt: '2022-04-12T17:22:35.219Z',
                locationType: 'TRUCK',
                tags: [],
              },
              category: {
                id: 12,
                title: 'Take Home',
                subHeading: '',
                locationType: 'TRUCK',
                customTag: null,
                isActive: true,
                updatedAt: '2022-04-12T17:29:18.098Z',
                createAt: '2022-04-12T17:29:18.098Z',
              },
            },
          ],
        },
        menu: {
          id: 5,
          title: 'Template - Do not delete',
          locationType: null,
          isActive: true,
          updatedAt: '2022-04-19T16:09:49.975Z',
          createAt: '2022-04-12T17:30:53.428Z',
          truckId: 1,
          truck: {
            id: 1,
            title: 'Template Truck',
            url: 'template',
            isActive: true,
            updatedAt: '2022-04-19T17:08:20.613Z',
            createAt: '2022-03-25T15:08:02.565Z',
          },
        },
      },
    ],
    truck: {
      id: 1,
      title: 'Template Truck',
      url: 'template',
      isActive: true,
      updatedAt: '2022-04-19T17:08:20.613Z',
      createAt: '2022-03-25T15:08:02.565Z',
    },
  };

  const menuIds = [id];
  const menus = [];

  if (id === MENU_IDS.SAN_FRAN_PIER_41.RESTAURANT_MENU_ID) {
    menuIds.push(MENU_IDS.SAN_FRAN_PIER_41.CART_MENU_ID);
  }

  for (const mId of menuIds) {
    const { data } = await axios.get(`${apiURL}/menus/${mId}`);

    menus.push(data);
  }

  console.log('getLocationMenu menus', menus);

  return menus;
};
