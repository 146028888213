import React, { useRef, useState } from 'react';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import Loader from 'react-loader-spinner';
import CheckIcon from '~/images/icons/checkbox.js';
import { css } from 'styled-components';
import { Heading, Subheading, BodyText } from '~/components/.base/headings';
import { StackedLogo } from '../Navigation/Logos';
import { Field } from 'formik';
import { FormControl, FormSuccess, CheckboxInput } from '~/components/.base/inputs';
import { RedButton } from '~/components/.base/buttons';
import { Row } from '~/components/.base/containers';
import './MailingListPopup.scss';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Formik } from 'formik';
import { subscribeToEmailList } from '~/helpers/requests/customer-requests';
import {
  ButtonContainer,
  CloseButton,
  Line,
  LogoContainer,
  LogoInnerContainer,
  MobileContainer,
  ModalBody,
  ModalBodyContainer,
  SuccessContainer,
} from './MailingListPopup.styled';

const MailingListPopup = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  const { images } = useStaticQuery(
    graphql`
      {
        images: allFile(filter: { absolutePath: { regex: "/images/joinMailingList/" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(quality: 100) {
                  aspectRatio
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );

  // useEffect(() => {
  //     if (!localStorage.getItem("hasVisited")) {
  //         setTimeout(() => setModalIsOpen(true), 5 * 1000);

  //         localStorage.setItem("hasVisited", "true");
  //     }
  // }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    setFormSuccess(false);

    const { email, interestedIn } = values;
    const onlineShop = !!interestedIn.includes('onlineShop');
    const trucksAndRestaurants = !!interestedIn.includes('trucksAndRestaurants');
    console.log(email, onlineShop, trucksAndRestaurants);
    subscribeToEmailList(email, onlineShop, trucksAndRestaurants)
      .then(res => {
        setSubmitting(false);
        setFormSuccess(true);
      })
      .catch(err => {
        setFormSuccess(false);
        setSubmitting(false);
      });
  };

  const trucksAndRestaurantsFieldRef = useRef(null);
  const emailFieldRef = useRef(null);

  return (
    <Modal
      open={modalIsOpen}
      role="dialog"
      aria-modal="true"
      onClose={() => setModalIsOpen(false)}
      aria-label="Join our mailing list"
      aria-described-by="location-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalIsOpen}>
        <ModalBodyContainer open={modalIsOpen} onClick={() => setModalIsOpen(false)}>
          <ModalBody onClick={e => e.stopPropagation()} top={'0'} className="modalBody">
            <div className="join-mailing-list-modal-body">
              <div className="left-half-mobile">
                <Image
                  fluid={images.edges[0].node.childImageSharp.fluid}
                  alt="Cousins Carrying Lobster Trap - Mobile"
                />
              </div>
              <div className="left-half ">
                <LogoContainer>
                  <LogoInnerContainer>
                    <StackedLogo isWhite={false} label="Cousins Maine Lobster logo" />
                  </LogoInnerContainer>
                </LogoContainer>
                <Image
                  fluid={images.edges[1].node.childImageSharp.fluid}
                  alt="Cousins Carrying Lobster Trap - Desktop"
                />
              </div>
              <div className="right-half">
                <Row style={{ width: '100%' }}>
                  <Heading md="0 0 15px 0">
                    <span>JOIN OUR</span>
                    <span
                      style={{
                        color: '#d4212c',
                      }}
                    >
                      {` MAILING LIST`}
                    </span>
                  </Heading>
                </Row>
                <Row style={{ width: '100%' }}>
                  <MobileContainer>
                    <BodyText
                      id="location-modal-description"
                      md="0 0 0px 0"
                      style={{
                        lineHeight: 1.5,
                      }}
                    >
                      Stay up to date on all the newest Cousins Maine Lobster news, special deals,
                      events & promotions!
                    </BodyText>
                  </MobileContainer>
                </Row>
                <Row style={{ width: '100%' }}>
                  <Line />
                </Row>
                {formSuccess ? (
                  <SuccessContainer>
                    <FormSuccess role="alert">Welcome to the family!</FormSuccess>
                  </SuccessContainer>
                ) : (
                  <Formik
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                      email: '',
                      interestedIn: ['trucksAndRestaurants', 'onlineShop'],
                    }}
                    validate={values => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = 'Required';
                        if (emailFieldRef?.current?.base) emailFieldRef.current.base.focus();
                      }
                      if (
                        values.email &&
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                      ) {
                        errors.email = 'Invalid email address';
                        if (emailFieldRef?.current?.base) emailFieldRef.current.base.focus();
                      }
                      if (!values.interestedIn.length) {
                        errors.interestedIn = 'You must select at least one interest';
                        if (trucksAndRestaurantsFieldRef?.current?.base)
                          trucksAndRestaurantsFieldRef.current.base.focus();
                      }
                      return errors;
                    }}
                    onSubmit={handleSubmit}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                      setValues,
                      isSubmitting,
                      touched,
                      values,
                    }) => (
                      <form autocomplete="off" onSubmit={handleSubmit}>
                        <fieldset>
                          <Subheading as="legend" color="#5c6566" md="0px 0 15px 0">
                            I'm interested in
                          </Subheading>
                          <Row>
                            <CheckboxInput>
                              <Field
                                ref={trucksAndRestaurantsFieldRef}
                                type="checkbox"
                                name="interestedIn"
                                value="trucksAndRestaurants"
                                id="trucksAndRestaurants"
                                aria-describedby="interestedInError"
                              />
                              {values.interestedIn.includes('trucksAndRestaurants') ? (
                                <CheckIcon
                                  className="icon"
                                  style={{
                                    marginRight: '.5rem',
                                    cursor: 'pointer',
                                  }}
                                />
                              ) : (
                                <div
                                  className="icon"
                                  css={css`
                                    margin-right: 0.5rem;
                                    border: 1px solid #5c6566;
                                    border-radius: 50%;
                                    cursor: pointer;
                                    width: 16px;
                                    height: 16px;
                                  `}
                                />
                              )}
                              <span>Trucks & Restaurants</span>
                            </CheckboxInput>
                            <CheckboxInput
                              style={{
                                marginLeft: '20px',
                              }}
                            >
                              <Field
                                type="checkbox"
                                name="interestedIn"
                                value="onlineShop"
                                id="onlineShop"
                              />
                              {values.interestedIn.includes('onlineShop') ? (
                                <CheckIcon
                                  className="icon"
                                  style={{
                                    marginRight: '.5rem',
                                    cursor: 'pointer',
                                  }}
                                />
                              ) : (
                                <div
                                  className="icon"
                                  css={css`
                                    margin-right: 0.5rem;
                                    border: 1px solid #5c6566;
                                    cursor: pointer;
                                    width: 16px;
                                    height: 16px;
                                  `}
                                />
                              )}
                              <span>Online Shops</span>
                            </CheckboxInput>
                          </Row>
                          <FormControl md="0">
                            <div id="interestedInError" className="error">
                              {errors.interestedIn}
                            </div>
                          </FormControl>
                        </fieldset>
                        <Row style={{ width: '100%' }}>
                          <Line />
                        </Row>
                        <Row style={{ width: '100%' }}>
                          <Subheading
                            as="label"
                            color="#5c6566"
                            md="0px 0 15px 0"
                            htmlFor="mailingListEmail"
                          >
                            Your Email
                          </Subheading>
                        </Row>
                        <Row
                          style={{
                            width: '100%',
                          }}
                        >
                          <FormControl md="0">
                            <Field
                              ref={emailFieldRef}
                              id="mailingListEmail"
                              style={{
                                color: '#5c6566',
                                width: '100%',
                              }}
                              placeHolder="Enter your email address..."
                              name="email"
                              type="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              autocomplete="email"
                              aria-describedby="emailError"
                              required
                            />
                            <div id="emailError" className="error">
                              {errors.email}
                            </div>
                          </FormControl>
                        </Row>
                        <Row
                          style={{
                            width: '100%',
                          }}
                        >
                          <Line />
                        </Row>
                        <Row style={{ width: '100%' }}>
                          <ButtonContainer>
                            <RedButton
                              type="submit"
                              w="100%"
                              onClick={handleSubmit}
                              disabled={isSubmitting}
                              h="50px"
                              pd="0 35px"
                            >
                              {isSubmitting ? (
                                <>
                                  <Loader type="TailSpin" color="#152622" height={16} width={16} />
                                  Loading...
                                </>
                              ) : (
                                'Sign Up'
                              )}
                            </RedButton>
                          </ButtonContainer>
                        </Row>
                      </form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
            <CloseButton onClick={() => setModalIsOpen(false)} aria-label="Close">
              &times;
            </CloseButton>
          </ModalBody>
        </ModalBodyContainer>
      </Fade>
    </Modal>
  );
};

export default MailingListPopup;
