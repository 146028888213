import styled from 'styled-components';
import { WhiteButton } from '~/components/.base/buttons';
import { CheckboxInput } from '~/components/.base/inputs';

export const ModalBodyContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 1024px) {
        margin-top: 20px;
        width: 90%;
        max-width: 480px;
        max-height: 80vh;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const MobileContainer = styled.div`
    display: none;
    @media screen and (min-width: 376px) {
        display: block;
    }
`;

export const SuccessContainer = styled.div`
    flex: 1;
    height: 100%;
    width: 100%;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #d4212c;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
`;

export const LogoContainer = styled.div`
    position: relative;
    max-width: 125px;
`;

export const LogoInnerContainer = styled.div`
    position: absolute;
    width: 100%;
    top: 33px;
    left: 33px;
    z-index: 10000;
`;

export const ModalBody = styled.div`
    top: ${props => props.top}%;
    position: relative;
    width: 90%;
    max-width: 1100px;
    max-width: ${props => props.modalMaxWidth};
    margin: auto;
    border-radius: 40px;
    background-color: #fff;
    // max-height: 520px;
    @media screen and (max-width: 1024px) {
        width: 100%;
        // height: 100%;
        max-width: none;
    }
    ${CheckboxInput} {
        .icon {
            border-radius: 50%;
        }
        input {
            opacity: 1;
            width: 100%;
            height: 100%;
            appearance: none;
            &:focus {
                box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(212, 33, 44, 0.75);
            }
        }
    }
`;

export const CloseButton = styled(WhiteButton)`
    font-size: 24px;
    right: 20px;
    top: 20px;
    position: absolute;
    height: 40px;
    width: 40px;
    padding: 0px;
    border-radius: 20px;
`;

export const Line = styled.div`
    width: 100%;
    border-top: 1px solid #edf2f2;
    margin: 15px 0px;
    display: none;

    @media screen and (min-width: 1025px) {
        margin: 20px 0px;
        display: block;
    }
`;

export const ButtonContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    @media screen and (min-width: 1025px) {
        margin-top: 0px;
    }
`;
