import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';
import MailingListPopup from '~/components/JoinMailingList/MailingListPopup';
import { DetailHero, DetailSchedule, DetailFooter, MenuModal } from '~/components/LocationPage';
import { useLocationImages } from 'hooks/use-locations-images';
import { useLocationMenu } from 'hooks/location';

const LocationPage = ({ data, location }) => {
  const {
    title,
    handle,
    seoTitle,
    seoDescription,
    calendarIds,
    heroImage,
    isATruck,
    restaurantSchedule,
    phoneNumber,
    emailAddress,
    physicalAddress,
    facebookLink,
    instagramLink,
    twitterLink,
    truckDetails,
    upperFooterBlocks,
    lowerFooterTitle,
    lowerFooterBlocks,
  } = data.contentfulFoodTruckPage;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const locationImages = useLocationImages().edges;

  const { locationMenus, handleLocationMenu } = useLocationMenu(handle);

  console.log('LocationPage locationMenus', locationMenus);

  return (
    <>
      <MailingListPopup />
      <SEO title={seoTitle || title} description={seoDescription} isLocationPage={true} />
      <Criteo />
      <DeriveHeaderClasses location={location} />
      <div>
        <div className="location-details">
          <DetailHero
            city={title}
            isATruck={isATruck}
            heroImage={heroImage}
            facebookLink={facebookLink}
            instagramLink={instagramLink}
            twitterLink={twitterLink}
            setModalIsOpen={setModalIsOpen}
          />
          <DetailSchedule
            name={title}
            isATruck={isATruck}
            calendarIds={calendarIds}
            physicalAddress={physicalAddress}
            restaurantSchedule={restaurantSchedule}
          />
          <DetailFooter
            isATruck={isATruck}
            title={title}
            truckDetails={truckDetails}
            phoneNumber={phoneNumber}
            emailAddress={emailAddress}
            physicalAddress={physicalAddress}
            upperFooterBlocks={upperFooterBlocks}
            lowerFooterTitle={lowerFooterTitle}
            lowerFooterBlocks={lowerFooterBlocks}
            setModalIsOpen={setModalIsOpen}
          />
          <MenuModal
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            locationImages={locationImages}
            locationMenus={locationMenus}
            phoneNumber={phoneNumber}
            emailAddress={emailAddress}
            handleLocationMenu={handleLocationMenu}
          />
        </div>
      </div>
    </>
  );
};

// TODO ADD query field "seoDescription" on type "ContentfulFoodTruckPage"
export const query = graphql`
  query ($handle: String!) {
    contentfulFoodTruckPage(handle: { eq: $handle }) {
      title
      handle
      calendarIds
      heroImage {
        fixed(width: 2000, quality: 100) {
          width
          height
          src
          srcSet
        }
      }
      isATruck
      phoneNumber
      emailAddress
      physicalAddress
      facebookLink
      instagramLink
      twitterLink
      # restaurantSchedule {
      #   title
      #   mondayHours
      #   tuesdayHours
      #   wednesdayHours
      #   thursdayHours
      #   fridayHours
      #   saturdayHours
      #   sundayHours
      #   mondayNote
      #   tuesdayNote
      #   wednesdayNote
      #   thursdayNote
      #   fridayNote
      #   saturdayNote
      #   sundayNote
      # }
      upperFooterBlocks {
        ... on ContentfulFoodTruckFooterModule1 {
          title
          heading
          description {
            description
          }
          image {
            description
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
            fixed(width: 1600, quality: 100) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
      lowerFooterTitle
      lowerFooterBlocks {
        ... on ContentfulFoodTruckFooterModule2 {
          buttonText
          link
          image {
            title
            fluid(maxWidth: 1600, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default LocationPage;
